import { default as _2_45via_45boletobU47xfitFjMeta } from "/app/pages/2-via-boleto.vue?macro=true";
import { default as canais_45de_45atendimentotknXQXzZIUMeta } from "/app/pages/canais-de-atendimento.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as home_45clienteND2WzVBbdOMeta } from "/app/pages/home-cliente.vue?macro=true";
import { default as homeH3NsyxtJwrMeta } from "/app/pages/home.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as institucional6ar4TRUihFMeta } from "/app/pages/institucional.vue?macro=true";
import { default as nossas_45lojasc2MStNHHUpMeta } from "/app/pages/nossas-lojas.vue?macro=true";
import { default as ouvidoria9eKM6N8HrBMeta } from "/app/pages/ouvidoria.vue?macro=true";
import { default as politica_45de_45privacidade8ARX5s1EBNMeta } from "/app/pages/politica-de-privacidade.vue?macro=true";
import { default as escolha_45sua_45cidadeDs5z2cAcpZMeta } from "/app/pages/promocao/ads/escolha-sua-cidade.vue?macro=true";
import { default as obrigadoS5RNOHCboeMeta } from "/app/pages/promocao/ads/obrigado.vue?macro=true";
import { default as planos6PQ6OaWBVVMeta } from "/app/pages/promocao/ads/planos.vue?macro=true";
import { default as selecionar_45planosFUrlAMclWGMeta } from "/app/pages/promocao/ads/selecionar-planos.vue?macro=true";
import { default as regulamentosDDtWvxJsM8Meta } from "/app/pages/regulamentos.vue?macro=true";
import { default as servicos_45digitaistg5x3zo0tmMeta } from "/app/pages/servicos-digitais.vue?macro=true";
export default [
  {
    name: "2-via-boleto",
    path: "/2-via-boleto",
    component: () => import("/app/pages/2-via-boleto.vue").then(m => m.default || m)
  },
  {
    name: "canais-de-atendimento",
    path: "/canais-de-atendimento",
    component: () => import("/app/pages/canais-de-atendimento.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home-cliente",
    path: "/home-cliente",
    component: () => import("/app/pages/home-cliente.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "institucional",
    path: "/institucional",
    component: () => import("/app/pages/institucional.vue").then(m => m.default || m)
  },
  {
    name: "nossas-lojas",
    path: "/nossas-lojas",
    component: () => import("/app/pages/nossas-lojas.vue").then(m => m.default || m)
  },
  {
    name: "ouvidoria",
    path: "/ouvidoria",
    component: () => import("/app/pages/ouvidoria.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-privacidade",
    path: "/politica-de-privacidade",
    component: () => import("/app/pages/politica-de-privacidade.vue").then(m => m.default || m)
  },
  {
    name: "promocao-ads-escolha-sua-cidade",
    path: "/promocao/ads/escolha-sua-cidade",
    component: () => import("/app/pages/promocao/ads/escolha-sua-cidade.vue").then(m => m.default || m)
  },
  {
    name: "promocao-ads-obrigado",
    path: "/promocao/ads/obrigado",
    component: () => import("/app/pages/promocao/ads/obrigado.vue").then(m => m.default || m)
  },
  {
    name: "promocao-ads-planos",
    path: "/promocao/ads/planos",
    component: () => import("/app/pages/promocao/ads/planos.vue").then(m => m.default || m)
  },
  {
    name: "promocao-ads-selecionar-planos",
    path: "/promocao/ads/selecionar-planos",
    component: () => import("/app/pages/promocao/ads/selecionar-planos.vue").then(m => m.default || m)
  },
  {
    name: "regulamentos",
    path: "/regulamentos",
    component: () => import("/app/pages/regulamentos.vue").then(m => m.default || m)
  },
  {
    name: "servicos-digitais",
    path: "/servicos-digitais",
    component: () => import("/app/pages/servicos-digitais.vue").then(m => m.default || m)
  }
]